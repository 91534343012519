<template>
  <div>
    <b-form-file
      ref="file-input"
      v-model="file"
      class="mb-2"
    />

    <p class="mt-2">
      Selected file: <b>{{ file ? file.name : '' }}</b>
    </p>
    <b-button @click="save">
      Save
    </b-button>

    <div v-if="order.attachment">
      <b-img-lazy
        :src="`${order.attachment}`"
        class="mx-1"
        width="150px"
        height="100px"
        fluid
      />
    </div>
  </div>
</template>

<script>
import {
  BFormFile, BButton, BImgLazy,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BFormFile,
    BButton,
    BImgLazy,
  },
  data() {
    return {
      file: null,
      id: null,
      order: null,
    }
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getPurchaseOrder()
  },
  methods: {
    clearFiles() {
      this.$refs['file-input'].reset()
    },
    async save() {
      try {
        const payload = new FormData()
        payload.append('id', this.id)
        payload.append('attachment', this.file)
        await axiosIns.put(`/purchase-orders/upload/${this.id}`, payload)
        this.$router.push({ name: 'purchase-orders' })
      } catch (e) {
        // this.showToast('danger', 'top-center', e.toString())
      }
    },
    async getPurchaseOrder() {
      const { data } = await axiosIns.get(`/purchase-orders/details/${this.id}/`)
      this.order = data
    },
  },
}
</script>
